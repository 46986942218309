import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BrowserFrame from '../components/BrowserFrame';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import RestLg from '../components/icons/RestLg';

const SalesforceCommerceCloudSubscriptionsPage = () => (
  <Layout>
    <Seo title="Salesforce Commerce Subscriptions and Recurring Orders" />

    {/* 
    For reference 
      https://rechargepayments.com/ecommerce/bigcommerce-subscriptions/
      https://rechargepayments.com/ecommerce/headless-ecommerce-api/
      https://rechargepayments.com/ecommerce/shopify-subscriptions/
    */}

    <Header graphic={undefined}>Salesforce Commerce Subscriptions</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="">
            <h2 className="text-6xl font-normal">Salesforce Commerce Cloud Subscriptions</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              Salesforce Commerce Cloud Subscriptions is a powerful subscription management tool that allows you to easily manage
              your subscription business. With Salesforce Commerce Cloud Subscriptions, you can create and manage subscriptions,
              automate billing and payments, and provide a seamless customer experience.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              Our platform is designed to be flexible and scalable, so you can easily adapt to changing business needs. Whether
              you're just starting out or you're a large enterprise, Salesforce Commerce Cloud Subscriptions can help you grow
              your subscription business.
            </p>
          </div>
          <div className="mt-10">
            <div className="flex justify-center items-start">
              <BrowserFrame className="max-w-[900px]">
                <StaticImage
                  className="object-contain "
                  src="../images/theraone_conductive_gel_pdp.png"
                  width={900}
                  quality={95}
                  objectFit="contain"
                  alt="Product Details Page Subscription Widget"
                />
              </BrowserFrame>
            </div>
          </div>
        </div>
      </Container>
    </section>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="mt-24 lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="col-start-2">
            <h2 className="text-5xl font-normal">Powerful and Flexible APIs</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              We offer APIs covering all features available in the Salesforce Commerce Cloud Subscriptions platform. The
              Salesforce Commerce Cloud Subscriptions API is a standard REST-style API that accepts and returns JSON. We are
              committed to making sure all data stored by our subscription commerce platform is available via API and that all
              operations may be completed via API as well.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              We have architected our platform to provide a solid foundation for the long term. Our support team is never more
              than a phone call away. And when you need our help, we strive to provide truly world class support.
            </p>
          </div>
          <div className="col-start-1 flex flex-col items-center">
            <RestLg className="w-[18rem] h-auto" />
          </div>
        </div>
      </Container>
    </section>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="">
            <h2 className="text-6xl font-normal">Powerful Merchant App</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              The Subscribe Pro Merchant App is powerful customer service tool. Your subscribers are
              your most loyal customers and we believe it is essential your customer service team has access to the best tools to
              manage those customers data.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              With our Merchant App, you can easily manage your subscribers, view their subscription
              details, and make changes to their subscriptions. Our platform is designed to be intuitive and easy to use, so your
              customer service team can quickly resolve any issues that arise.
            </p>
          </div>
          <div className="mt-10">
            <div className="flex justify-center items-start">
              <BrowserFrame className="max-w-[900px]">
                <StaticImage
                  className="object-contain "
                  src="../images/sp_merchant_app_customers_demo.png"
                  width={900}
                  quality={95}
                  objectFit="contain"
                  alt="Subscribe Pro Merchant App | Manager your loyal subscription customers and their data"
                />
              </BrowserFrame>
            </div>
          </div>
        </div>
      </Container>
    </section>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="mt-24 lg:mx-auto lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="col-start-2">
            <h2 className="text-5xl font-normal">Certified SFRA Cartridge</h2>
            <p className="mt-4 text-lg text-sp-black font-light">
              Salesforce Commerce Cloud Subscriptions is highly integrated with Salesforce Commerce Cloud. Our Commerce Cloud
              Cartridge is certified for{' '}
              <a
                href="https://developer.salesforce.com/docs/commerce/sfra/guide/b2c-sfra-features-and-comps.html"
                target="_blank"
                rel="nofollow noreferrer"
              >
                SFRA
              </a>{' '}
              and SiteGenesis. We work with all the leading payment providers on Salesforce Commerce Cloud.
            </p>

            <p className="mt-4 text-lg text-sp-black font-light">
              We have architected our platform to provide a solid foundation for the long term. Our support team is never more
              than a phone call away. And when you need our help, we strive to provide truly world class support.
            </p>
          </div>
          <div className="col-start-1 flex flex-col items-center">
            <BrowserFrame className="max-w-[900px]">
              <StaticImage
                className="object-contain "
                src="../images/sfra_site.png"
                width={900}
                quality={95}
                objectFit="contain"
                alt="Salesforce Commerce Cloud | B2C Commerce Storefront Reference Architecture | SFRA"
              />
            </BrowserFrame>
          </div>
        </div>
      </Container>
    </section>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default SalesforceCommerceCloudSubscriptionsPage;
